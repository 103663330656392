import React from 'react';
import LocalizedLink from './LocalizedLink';
import { graphql, useStaticQuery, Link } from 'gatsby';

const MobileMenu = props => {
  const data = useStaticQuery(graphql`
    query MainMobileMenuQuery {
      allMainMenuJson {
        edges {
          node {
            name {
              en
              by
            }
            url
            weight
          }
        }
      }
    }
  `);
  return (
    <div id="main-menu-mobile" className={`main-menu-mobile ${props.active ? 'open' : ''}`}>
      <ul>
        {data.allMainMenuJson.edges.map(({ node }) => (
          <li key={node.name[props.locale]}>
            <LocalizedLink to={node.url} activeClassName="active">{node.name[props.locale]}</LocalizedLink>
          </li>
        ))}
        <li key="lang">
          <Link to="/" hrefLang="en">en</Link><span>/</span><Link to="/by" hrefLang="by">by</Link>
        </li>
      </ul>
    </div>
  );
};

export default MobileMenu;
