module.exports = {
  en: {
    default: true,
    path: `en`,
    locale: `en-US`,
    siteLanguage: `en`,
    ogLanguage: `en_US`
  },
  by: {
    path: `by`,
    locale: `by-BY`,
    siteLanguage: `by`,
    ogLanguage: `by_BY`
  }
}
