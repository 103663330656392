import React from 'react';
import translations from './useTranslation';

const ContactForm = props => {
  const { firstName, lastName, emailAddress, message, send } = translations();
  return (
    <div className="call">
      <div className="call-box-top">
        <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" class="form">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <p>
            <label>{firstName}*<br/><input type="text" name="first-name" required /></label>   
          </p>
          <p>
            <label>{lastName}*<br/><input type="text" name="last-name" required /></label>   
          </p>
          <p>
            <label>{emailAddress}*<br/><input type="email" name="email" required /></label>
          </p>
          <p>
            <label>{message}*<br/><textarea name="message" required ></textarea></label>
          </p>
	      <p>
            <button type="submit" class="button">{send}</button>
          </p>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;

