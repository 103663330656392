import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Menu from './Menu';
import Hamburger from './Hamburger';
import logo from '../../static/images/logo/logo.png';
import logoMobile from '../../static/images/logo/logo.png';
import MenuMobile from './MenuMobile';
import { LocaleContext } from './Layout';
import LocalizedLink from './LocalizedLink';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false
    };
  }

  toggleMenu = menuActive => {
    this.setState(prevState => ({
      menuActive: !prevState.menuActive
    }));
  };

  render() {
    const config = this.props.data.configJson;
    return (
      <LocaleContext.Consumer>
        {(locale) =>
          <div className="header">
            <div className="container">
              <div className="logo">
	    	    <LocalizedLink to="/">
                  <img height={config.logo.desktop_height} alt={config.logo.alt} src={config.logo.desktop} />
                </LocalizedLink>
              </div>
              <div className="logo-mobile">
	    	    <LocalizedLink to="/">
                  <img height={config.logo.desktop_height} alt={config.logo.alt} src={config.logo.mobile} />
                </LocalizedLink>
              </div>
              <MenuMobile active={this.state.menuActive} locale={locale} />
              <Menu locale={locale} />
              <Hamburger toggleMenu={this.toggleMenu} />
            </div>
          </div>
        }
      </LocaleContext.Consumer>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        configJson {
          logo {
            alt
            desktop
            mobile
            desktop_height
          }
        }
      }
    `}
    render={data => <Header data={data} />}
  />
);
