import React from 'react';
import { MDXProvider } from "@mdx-js/react"
import SEO from './SEO';
import Header from './Header';
import Footer from './Footer';
import SubFooter from './SubFooter';
import '../scss/style.scss';
import MdxLink from "./MdxLink"

const LocaleContext = React.createContext();

const Layout = ({ bodyClass, children, pageContext }) => (
  <LocaleContext.Provider value={ pageContext.locale }>
    <SEO />
    <div className={`page${bodyClass ? ` ${bodyClass}` : ''}`}>
      <div id="wrapper" className="wrapper">
        <Header locale={ pageContext.locale }/>
        {children}
      </div>
      <Footer />
      <SubFooter />
    </div>
  </LocaleContext.Provider>
);

export { Layout, LocaleContext };
