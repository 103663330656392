import React from 'react';
import translations from './useTranslation';

const JoinForm = props => {
  const { firstName, lastName, emailAddress, events, fundraising, activism, socialMedia, pr, other, message, send } = translations();
  return (
    <div className="call">
      <div className="call-box-top">
        <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="join" className="form">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="join" />
          <p>
            <label>{firstName}*<br/><input type="text" name="first-name" required /></label>   
          </p>
          <p>
            <label>{lastName}*<br/><input type="text" name="last-name" required /></label>   
          </p>
          <p>
            <label>{emailAddress}*<br/><input type="email" name="email" required /></label>
          </p>
          <p>
            <label><input type="checkbox" id="interest-events" name="interest-events" value="Events"/> {events}</label><br/>
            <label><input type="checkbox" id="interest-fundrasing" name="interest-fundrasing" value="Fundrasing"/> {fundraising}</label><br/>
            <label><input type="checkbox" id="interest-activism" name="interest-activism" value="Activism"/> {activism}</label><br/>
            <label><input type="checkbox" id="interest-social-media" name="interest-social-media" value="Social Media"/> {socialMedia}</label><br/>
            <label><input type="checkbox" id="interest-pr" name="interest-pr" value="PR"/> {pr}</label><br/>
            <label><input type="checkbox" id="interest-other" name="interest-other" value="Other"/> {other}</label><br/>
          </p>
          <p>
            <label>{message}*<br/><textarea name="message" required ></textarea></label>
          </p>
	      <p>
            <button type="submit" class="button">{send}</button>
          </p>
        </form>
      </div>
    </div>
  );
};

export default JoinForm;

