import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { LocaleContext } from './Layout';
import LocalizedLink from './LocalizedLink';

const Footer = props => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allFooterMenuJson {
        edges {
          node {
            weight
            url
            name {
              en
              by
            }
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
      <LocaleContext.Consumer>
        {(locale) =>
          <div className="footer">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="footer-inner">
                    <h3 className="footer-title">{data.site.siteMetadata.title}</h3>
                    <ul>
                      {data.allFooterMenuJson.edges.map(({ node }) => (
                        <li key={node.name[locale]}>
                          <LocalizedLink to={node.url}>{node.name[locale]}</LocalizedLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </LocaleContext.Consumer>
  );
};

export default Footer;
