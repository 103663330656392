import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { LocaleContext } from "./Layout";

const useTranslations = () => {
  const locale = useContext(LocaleContext);
  const { rawData } = useStaticQuery(query);

  console.log("locale", locale);
  console.log("rawData", rawData);

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map(item => {
    return {
      name: item.node.name,
      translations: item.node.translations,
    }
  });

  // Only return translations for the current locale
  const { translations } = simplified.filter(lang => lang.name === locale)[0];

  return translations;
}

export default useTranslations

const query = graphql`
  query useTranslations {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            metaDescription
            shareSkills
            joinOurCause
            viewAll
            resources
            firstName
            lastName
            emailAddress
            message
            events
            fundraising
            activism
            socialMedia
            pr
            other
            send
          }
        }
      }
    }
  }
`
