import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { LocaleContext } from './Layout';
import favicon32 from '../../static/favicon-32x32.png';
import favicon16 from '../../static/favicon-16x16.png';
import faviconApple from '../../static/apple-touch-icon.png';

const SEO = props => (
  <LocaleContext.Consumer>
    {(locale) =>
      <StaticQuery
        query={detailsQuery}
        render={data => {
          const title = props.title || data.site.siteMetadata.title;
          return (
            <Helmet
              htmlAttributes={{
                lang: locale
              }}
              title={title}
              titleTemplate={props.title ? `%s` : `%s - ${data.site.siteMetadata.title}`}
              link={[
                { rel: 'shortcut icon', type: 'image/png', sizes: '32x32', href: `${favicon32}` },
                { rel: 'shortcut icon', type: 'image/png', sizes: '16x16', href: `${favicon16}` },
                { rel: 'apple-touch-icon', sizes: '180x180', href: `${faviconApple}` },
              ]}
            />
          );
        }}
      />
    }
  </LocaleContext.Consumer>
);

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: []
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
