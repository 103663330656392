import React from "react"
import { Link } from "gatsby"
import { LocaleContext } from "./Layout"
import Locales from "../data/config/i18n"

// Use the globally available context to choose the right path
const LocalizedLink = ({ to, ...props }) => {
  return (
    <LocaleContext.Consumer>
      {(locale) => {
        const isIndex = to === `/`
        // If it's the default language, don't do anything
        // If it's another language, add the "path"
        // However, if the homepage/index page is linked don't add the "to"
        // Because otherwise this would add a trailing slash
        const path = Locales[locale].default
          ? to
          : `/${Locales[locale].path}${isIndex ? `` : `${to}`}`
        return <Link {...props} to={path} />
	  }}
    </LocaleContext.Consumer>
  )
}

export default LocalizedLink
